import "./index.css";

import * as React from "react";

import { widget } from "../../charting_library";

let callbacks = {
  interval: undefined,
};
let tvWidget;
let entities = { shapes: [], studies: [] };
let buffer = { shapes: [], studies: [], shapeReady: true, studiesReady: true };
const DELAY = 600;
let interval =
  localStorage.getItem("tradingview.chart.lastUsedTimeBasedResolution") || "1D";
let isReady = false;
class TVChartContainer extends React.PureComponent {
  constructor(props) {
    tvWidget = null;
    super(props);
    isReady = false;
    this.ref = React.createRef(null);
    this.mode = this.props?.mode;
    this.identity = "identity";
    this.symbol = this.props?.symbol;
    this.client = this.props?.client;
    this.postAnalysis = this.props?.postAnalysis;
    this.alarm = this.props?.alarm;
    this.replay = this.props?.replay;
    this.note = this.props?.note;
    this.onChangeSymbol = this.props?.onChangeSymbol;
    this.onIntervalChanged = this.props?.onIntervalChanged;
    interval = this.props?.interval || interval;
    buffer = { shapes: [], studies: [], shapeReady: true, studiesReady: true };
  }
  loadChart() {
    this.option = {
      charts_storage_url: "chart_url",
      library_path: "/charting_library/",
      client_id: "client_id",
      autosize: true,
      disabled_features: this.props?.disabledFeatures,
      enabled_features: [
        ...this.props?.enabledFeatures,
        "side_toolbar_in_fullscreen_mode",
        "header_in_fullscreen_mode",
      ],
      charts_storage_api_version: this.props?.public ? "1.2" : "1.1",
      symbol: this.props?.symbol,
      datafeed: this.props?.datafeed,
      interval: interval,
      container: this.ref.current,
      theme: this.props?.mode,
      user_id: this.identity,
      auto_save_delay: 5,
      studies_overrides: this.props?.studiesOverrides,
      timezone: this.props?.timezone || "Asia/Tehran",
      load_last_chart: this.props?.loadLastChart,
      loading_screen: {
        backgroundColor: this.props?.mode === "light" ? "#fdfdfd" : "#12131e",
        foregroundColor: this.props?.mode === "light" ? "#4080ee" : "#9e8548",
      },
      overrides: {
        "symbolWatermarkProperties.visibility": true,
        "symbolWatermarkProperties.color": "rgba(125, 125, 125, 0.1)",
      },
    };
    const autoSave = this.props?.autoSave;
    const initialInterval = this.props?.interval;
    const postAnalysis = this.postAnalysis;
    const alarm = this.alarm;
    const replay = this.replay;
    const note = this.note;
    const onIntervalChanged = this.onIntervalChanged;
    tvWidget = new widget(this.option);
    if (postAnalysis) {
      tvWidget.headerReady().then(function () {
        var button = tvWidget.createButton();
        button.setAttribute("title", "Post analysis");
        button.addEventListener("click", () => {
          saveChart()
            .then(() => {
              saveChartToJS().then((obj) => {
                const { from, to } = tvWidget.activeChart().getVisibleRange();
                postAnalysis(
                  obj.name,
                  from,
                  to,
                  tvWidget.activeChart().resolution(),
                );
              });
            })
            .catch(() => {
              postAnalysis(undefined, 1, 2, 15);
            });
        });
        button.textContent = "Post";
      });
    }
    if (alarm) {
      tvWidget.headerReady().then(function () {
        var button = tvWidget.createButton();
        button.setAttribute("title", "Alarms list");
        button.addEventListener("click", () => {
          alarm();
        });
        button.textContent = "Alarms";
      });
    }
    if (replay) {
      tvWidget.headerReady().then(function () {
        var button = tvWidget.createButton();
        button.setAttribute("title", "Create Replay");
        button.addEventListener("click", () => {
          replay();
        });
        button.textContent = "Replay";
      });
    }
    if (note) {
      tvWidget.headerReady().then(function () {
        var button = tvWidget.createButton();
        button.setAttribute("title", "Note list");
        button.addEventListener("click", () => {
          note();
        });
        button.textContent = "Note";
      });
    }
    const removeShapes = () => {
      entities.shapes.forEach((shape) => {
        setTimeout(() => {
          if (shape.id !== 0) tvWidget.activeChart().removeEntity(shape.id);
        }, DELAY);
      });
    };
    const removeStudies = () => {
      entities.studies.forEach((shape) => {
        setTimeout(() => {
          if (shape.id !== 0) tvWidget.activeChart().removeEntity(shape.id);
        }, DELAY);
      });
    };
    const removeEntities = () => {
      removeShapes();
      removeStudies();
    };
    const plotShapes = (i) => {
      if (entities.shapes.length > i) {
        const ids = tvWidget
          .activeChart()
          .getAllShapes()
          .map(({ id, name }) => id);

        if (!ids.includes(entities.shapes[i]["id"]))
          entities.shapes[i]["fun"]().then((id) => {
            entities.shapes[i]["id"] = id;
            plotShapes(i + 1);
          });
      }
    };
    const plotStudies = (i) => {
      if (entities.studies.length > i) {
        const ids = tvWidget
          .activeChart()
          .getAllStudies()
          .map(({ id, name }) => id);
        if (!ids.includes(entities.studies[i]["id"]))
          entities.studies[i]["fun"]().then((id) => {
            entities.studies[i]["id"] = id;
            plotStudies(i + 1);
          });
      }
    };
    const plotEntities = () => {
      setTimeout(() => {
        plotShapes(0);
        plotStudies(0);
      }, 2 * DELAY);
    };
    tvWidget.onChartReady(() => {
      isReady = true;
      localStorage.getItem("tradingview.current_theme.name") !== this.mode &&
        tvWidget.changeTheme(this.mode);
      plotEntities();
      if (autoSave) {
        tvWidget.subscribe("onAutoSaveNeeded", () => {
          tvWidget.saveChartToServer(
            () => {},
            () => {},
            { defaultChartName: "unNamed" },
          );
        });
      }
      tvWidget.subscribe("chart_loaded", () => {
        setTimeout(() => {
          localStorage.getItem("tradingview.current_theme.name") !==
            this.mode && tvWidget.changeTheme(this.mode);
          if (initialInterval) {
            tvWidget.activeChart().setResolution(initialInterval);
          }
          plotEntities();
        }, DELAY);
      });
      tvWidget
        .activeChart()
        .onIntervalChanged()
        .subscribe(null, (val, timeframeObj) => {
          plotEntities();
          onIntervalChanged(val);
          callbacks.interval && callbacks.interval(val);
          interval = val;
        });
      tvWidget
        .activeChart()
        .onSymbolChanged()
        .subscribe(null, () => {
          this.onChangeSymbol &&
            this.onChangeSymbol(tvWidget.activeChart().symbol());
        });
    });
  }
  componentDidMount() {
    this.loadChart();
  }
  componentDidUpdate() {
    if (this.props.mode !== this.mode) {
      this.mode = this.props.mode;
      tvWidget.changeTheme(this.props.mode);
    }
    if (this.props.symbol !== this.symbol) {
      tvWidget.activeChart().setSymbol(this.props.symbol);
    }
    if (
      (this.props.identity && this.props.identity !== this.identity) ||
      this.props.client !== this.client
    ) {
      this.loadChart();
      this.identity = this.props.identity;
      this.client = this.props.client;
      return true;
    }
    return false;
  }
  componentWillUnmount() {
    entities = { shapes: [], studies: [] };
    buffer = {
      shapes: [],
      studies: [],
      shapeReady: true,
      studiesReady: true,
    };
    isReady = false;
    if (tvWidget !== null) {
      tvWidget.remove();
      tvWidget = null;
    }
  }

  render() {
    return (
      <div
        ref={this.ref}
        style={{ height: this.props.height, width: this.props.width }}
      />
    );
  }
}

export default TVChartContainer;

export const clearMarks = () => {
  if (tvWidget) tvWidget.activeChart().clearMarks();
};

export const saveChart = (chartName) => {
  return new Promise((resolve, reject) => {
    if (!tvWidget) reject();
    tvWidget.saveChartToServer(
      (state) => {
        resolve();
      },
      (state) => {
        reject();
      },
      { chartName: chartName },
    );
  });
};
const saveChartToJS = () => {
  return new Promise((resolve, reject) => {
    if (!tvWidget) reject();
    tvWidget.save((obj) => {
      resolve(obj);
    });
  });
};
export const loadLastChart = () => {
  return new Promise((resolve, reject) => {
    if (!tvWidget) reject();
    tvWidget.getSavedCharts((charts) => {
      tvWidget.loadChartFromServer(charts[charts.length - 1]);
      resolve();
    });
  });
};

export const onChangeInterval = (callback) => {
  callbacks.interval = callback;
};

export const focusTo = (timestamp, callback) => {
  const fun = () => {
    return new Promise((resolve, reject) => {
      tvWidget
        .activeChart()
        .setVisibleRange({
          from: timestamp - 100 * 3600,
        })
        .then(() => {
          callback();
          resolve();
        });
    });
  };
  if (isReady) {
    if (buffer.shapeReady) {
      buffer.shapeReady = false;
      fun().then((id) => {
        buffer.shapeReady = true;
        shapeBuffer();
      });
    } else {
      buffer.shapes.push(fun);
    }
  } else {
    entities.shapes.push({ fun: fun, id: 0 });
  }
};
export const addShape = (points, shapeObj, offset = 100, zoom = true) => {
  const fun = () => {
    return new Promise((resolve, reject) => {
      if (tvWidget && tvWidget.activeChart) {
        if (zoom && points[0].time) {
          const id = tvWidget
            .activeChart()
            .createMultipointShape(points, shapeObj);
          resolve(id);
        } else {
          const id = tvWidget
            .activeChart()
            .createMultipointShape(points, shapeObj);
          resolve(id);
        }
      } else {
        reject();
      }
    });
  };
  if (isReady) {
    if (buffer.shapeReady) {
      buffer.shapeReady = false;
      const runner = () => {
        fun()
          .then((id) => {
            entities.shapes.push({ fun: fun, id: id });
            buffer.shapeReady = true;
            shapeBuffer();
          })
          .catch(() => {
            setTimeout(runner, 1000);
          });
      };
      runner();
    } else {
      buffer.shapes.push(fun);
    }
  } else {
    entities.shapes.push({ fun: fun, id: 0 });
  }
};
const shapeBuffer = () => {
  if (buffer.shapes.length === 0) {
    buffer.shapeReady = true;
    return;
  }
  buffer.shapes[0]().then((id) => {
    entities.shapes.push({ fun: buffer.shapes[0], id: id });
    buffer.shapes = buffer.shapes.slice(1);
    shapeBuffer();
  });
};
const studiesBuffer = () => {
  if (buffer.studies.length === 0) {
    buffer.studiesReady = true;
    return;
  }
  buffer.studies[0]().then((id) => {
    entities.studies.push({ fun: buffer.studies[0], id: id });
    buffer.studies = buffer.studies.slice(1);
    studiesBuffer();
  });
};
export const addIndicator = (name, inputs, option, lock = true) => {
  const fun = () => {
    return new Promise((resolve, reject) => {
      if (tvWidget && tvWidget.activeChart) {
        tvWidget
          .activeChart()
          .createStudy(name, false, lock, inputs, null, option)
          .then((id) => {
            resolve(id);
          });
      } else {
        reject();
      }
    });
  };
  if (isReady) {
    if (buffer.studiesReady) {
      buffer.studiesReady = false;
      const runner = () => {
        fun()
          .then((id) => {
            entities.studies.push({ fun: fun, id: id });
            buffer.studiesReady = true;
            studiesBuffer();
          })
          .catch(() => {
            setTimeout(runner, 1000);
          });
      };
      runner();
    } else {
      buffer.studies.push(fun);
    }
  } else {
    entities.studies.push({ fun: fun, id: 0 });
  }
};
