function Header() {
  return (
    <header>
      <video src="./video.mkv" loop autoPlay muted />
      <h1>Ai Algo Trading platform</h1>
      <div className="row">
        <button className="btn" style={{ cursor: "pointer" }}>
          Join Us
        </button>
      </div>
      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
