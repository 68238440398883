import Card from "./Card";
function Services() {
  return (
    <div className="container services">
      <h2 className="main-title text-center">SERVICES</h2>
      <div className="card-cover">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-2">
              <Card
                title="Alert Signals"
                img="card1.png"
                text="The trading assistant platform alert highlights a strong bullish signal, signifying a potential upward trend and an attractive trading opportunity. Users are urged to thoroughly review the detailed analysis and charts, recognizing the importance of staying informed and taking proactive steps."
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-bell-plus"
                  width="64"
                  height="64"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="#01bf71"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12.5 17h-8.5a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6v1"></path>
                  <path d="M9 17v1a3 3 0 0 0 3.51 2.957"></path>
                  <path d="M16 19h6"></path>
                  <path d="M19 16v6"></path>
                </svg>
              </Card>
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Auto Trade"
                img="card2.png"
                text="With auto trade functionality involves acquiring real-time market data, developing trading strategies, implementing risk management measures, executing trades through a broker API, backtesting and optimizing strategies, monitoring performance, handling errors and exceptions, ensuring compliance with regulations, and maintaining the system over time."
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-currency-dollar"
                  width="64"
                  height="64"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="#01bf71"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"></path>
                  <path d="M12 3v3m0 12v3"></path>
                </svg>
              </Card>
            </div>
            <div className="col-md-4 mb-2">
              <Card
                title="Back Test Plus"
                img="card3.png"
                text=" Implementing a backtesting feature in a trading assistant platform involves acquiring historical market data, defining trading strategies, executing simulated trades, evaluating performance metrics, visualizing results, optimizing parameters, conducting sensitivity analysis, validating against real-world trading, and continuously improving the process."
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-arrow-back-up-double"
                  width="64"
                  height="64"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="#01bf71"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M13 14l-4 -4l4 -4"></path>
                  <path d="M8 14l-4 -4l4 -4"></path>
                  <path d="M9 10h7a4 4 0 1 1 0 8h-1"></path>
                </svg>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Services;
