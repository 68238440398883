import { useEffect } from "react";
import About from "../components/About";
import Contact from "../components/Contact";
import Services from "../components/Services";
import exchangeDatafeed from "../datafeeds/exchangeDatafeed";
import TVChartContainer, { addShape } from "./TVChartContainer";

function Main() {
  useEffect(() => {
    setTimeout(() => {
      addShape(
        [
          {
            time: 1693324800,
            price: 28142.85,
          },
          {
            time: 1693479600,
            price: 27587.51,
          },
        ],
        {
          shape: "trend_line",
          disableSave: true,
          overrides: {
            extendRight: true,
            showLabel: true,
            fontSize: 30,
            linewidth: 2,
          },
        },
        0,
      );
    }, 5000);
  }, []);
  return (
    <>
      <main>
        <Services />
        <div
          style={{
            padding: "16px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              padding: "16px",
              width: "100%",
              maxWidth: "1024px",
            }}
          >
            <TVChartContainer
              symbol={"BTCUSDT"}
              interval={60}
              datafeed={exchangeDatafeed(
                [5, 15, 60, 240, "1D"],
                () => {},
                () => {},
                () => {},
              )}
              disabledFeatures={[]}
              enabledFeatures={["study_templates"]}
              height={"600px"}
              width={"100%"}
            />
          </div>
        </div>
        <About />
        <Contact />
      </main>
    </>
  );
}
export default Main;
