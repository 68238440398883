const chartCandles = [
  {
    t: 1691848800,
    o: 29408.81,
    h: 29450,
    l: 29408.8,
    c: 29449.98,
    v: 406.48731,
  },
  {
    t: 1691852400,
    o: 29449.99,
    h: 29458.1,
    l: 29438.81,
    c: 29438.81,
    v: 328.85686,
  },
  {
    t: 1691856000,
    o: 29438.81,
    h: 29481.35,
    l: 29438.81,
    c: 29456.74,
    v: 443.62077,
  },
  {
    t: 1691859600,
    o: 29456.75,
    h: 29457.56,
    l: 29430.11,
    c: 29430.11,
    v: 289.40083000000004,
  },
  {
    t: 1691863200,
    o: 29430.11,
    h: 29436.25,
    l: 29418.54,
    c: 29429.11,
    v: 278.44075000000004,
  },
  {
    t: 1691866800,
    o: 29429.12,
    h: 29429.12,
    l: 29411.58,
    c: 29416.16,
    v: 251.98702000000003,
  },
  {
    t: 1691870400,
    o: 29416.17,
    h: 29426.87,
    l: 29416.16,
    c: 29420.38,
    v: 201.17155,
  },
  {
    t: 1691874000,
    o: 29420.38,
    h: 29420.39,
    l: 29400,
    c: 29417.38,
    v: 258.03485,
  },
  {
    t: 1691877600,
    o: 29417.38,
    h: 29431.08,
    l: 29417.38,
    c: 29426.5,
    v: 195.6522,
  },
  {
    t: 1691881200,
    o: 29426.5,
    h: 29433.16,
    l: 29426.49,
    c: 29430.17,
    v: 180.21382,
  },
  {
    t: 1691884800,
    o: 29430.18,
    h: 29450,
    l: 29425.06,
    c: 29449.99,
    v: 174.63804,
  },
  {
    t: 1691888400,
    o: 29450,
    h: 29452.75,
    l: 29442.39,
    c: 29452.75,
    v: 248.58278,
  },
  {
    t: 1691892000,
    o: 29452.75,
    h: 29455.21,
    l: 29446.31,
    c: 29446.31,
    v: 166.85566,
  },
  {
    t: 1691895600,
    o: 29446.32,
    h: 29446.32,
    l: 29400,
    c: 29418.56,
    v: 578.61403,
  },
  {
    t: 1691899200,
    o: 29418.56,
    h: 29418.57,
    l: 29384.1,
    c: 29394.68,
    v: 493.5418,
  },
  {
    t: 1691902800,
    o: 29394.69,
    h: 29394.69,
    l: 29363,
    c: 29385,
    v: 447.40776000000005,
  },
  {
    t: 1691906400,
    o: 29384.99,
    h: 29401.56,
    l: 29381.56,
    c: 29401.56,
    v: 431.35645,
  },
  {
    t: 1691910000,
    o: 29401.55,
    h: 29407.5,
    l: 29395.9,
    c: 29396.36,
    v: 463.80512999999996,
  },
  {
    t: 1691913600,
    o: 29396.37,
    h: 29414.53,
    l: 29391.85,
    c: 29414.53,
    v: 603.04251,
  },
  {
    t: 1691917200,
    o: 29414.53,
    h: 29414.53,
    l: 29408,
    c: 29413.7,
    v: 530.61609,
  },
  {
    t: 1691920800,
    o: 29413.7,
    h: 29416.53,
    l: 29403.86,
    c: 29403.86,
    v: 476.47436,
  },
  {
    t: 1691924400,
    o: 29403.86,
    h: 29403.87,
    l: 29375.49,
    c: 29383.38,
    v: 244.98533000000003,
  },
  {
    t: 1691928000,
    o: 29383.39,
    h: 29393.76,
    l: 29367.89,
    c: 29389.99,
    v: 403.62845000000004,
  },
  {
    t: 1691931600,
    o: 29390,
    h: 29390.01,
    l: 29373.39,
    c: 29375.99,
    v: 398.87396,
  },
  {
    t: 1691935200,
    o: 29376,
    h: 29386.3,
    l: 29371.64,
    c: 29371.64,
    v: 297.80112999999994,
  },
  {
    t: 1691938800,
    o: 29371.65,
    h: 29380.26,
    l: 29362,
    c: 29374.2,
    v: 407.67124,
  },
  {
    t: 1691942400,
    o: 29374.19,
    h: 29374.2,
    l: 29328.21,
    c: 29353.59,
    v: 787.5473200000001,
  },
  {
    t: 1691946000,
    o: 29353.59,
    h: 29420,
    l: 29353.58,
    c: 29400.02,
    v: 558.33875,
  },
  {
    t: 1691949600,
    o: 29400.02,
    h: 29428.67,
    l: 29396.79,
    c: 29422.12,
    v: 394.23418,
  },
  {
    t: 1691953200,
    o: 29422.13,
    h: 29474.65,
    l: 29422.12,
    c: 29442.91,
    v: 661.52565,
  },
  {
    t: 1691956800,
    o: 29442.91,
    h: 29446,
    l: 29420,
    c: 29420.01,
    v: 279.07647,
  },
  {
    t: 1691960400,
    o: 29420.01,
    h: 29427.03,
    l: 29367.2,
    c: 29367.2,
    v: 443.37192,
  },
  {
    t: 1691964000,
    o: 29367.2,
    h: 29376.71,
    l: 29272.32,
    c: 29319.41,
    v: 1157.16474,
  },
  {
    t: 1691967600,
    o: 29319.42,
    h: 29334.77,
    l: 29298,
    c: 29303.84,
    v: 452.55572,
  },
  {
    t: 1691971200,
    o: 29303.85,
    h: 29325.29,
    l: 29250,
    c: 29266,
    v: 1186.71847,
  },
  {
    t: 1691974800,
    o: 29266,
    h: 29298.52,
    l: 29205.15,
    c: 29268,
    v: 1436.19039,
  },
  {
    t: 1691978400,
    o: 29268,
    h: 29361.99,
    l: 29102.45,
    c: 29319.99,
    v: 5252.50224,
  },
  {
    t: 1691982000,
    o: 29320,
    h: 29429.95,
    l: 29319.99,
    c: 29399.91,
    v: 1484.39708,
  },
  {
    t: 1691985600,
    o: 29399.91,
    h: 29450,
    l: 29395.24,
    c: 29413.99,
    v: 935.7096800000002,
  },
  {
    t: 1691989200,
    o: 29413.99,
    h: 29453.47,
    l: 29413.99,
    c: 29447.6,
    v: 752.58145,
  },
  {
    t: 1691992800,
    o: 29447.6,
    h: 29469.63,
    l: 29411,
    c: 29419.84,
    v: 513.95842,
  },
  {
    t: 1691996400,
    o: 29419.84,
    h: 29434.85,
    l: 29392.6,
    c: 29422.01,
    v: 784.35608,
  },
  {
    t: 1692000000,
    o: 29422.01,
    h: 29495.76,
    l: 29422,
    c: 29426.5,
    v: 1074.6589,
  },
  {
    t: 1692003600,
    o: 29426.49,
    h: 29434,
    l: 29386.35,
    c: 29413.99,
    v: 868.8548,
  },
  {
    t: 1692007200,
    o: 29414,
    h: 29450.06,
    l: 29404.15,
    c: 29433.58,
    v: 664.54271,
  },
  {
    t: 1692010800,
    o: 29433.57,
    h: 29433.58,
    l: 29383.63,
    c: 29409.99,
    v: 559.4901600000001,
  },
  {
    t: 1692014400,
    o: 29410,
    h: 29410,
    l: 29348.01,
    c: 29384.01,
    v: 800.44143,
  },
  {
    t: 1692018000,
    o: 29384,
    h: 29400,
    l: 29332.61,
    c: 29384.92,
    v: 860.48613,
  },
  {
    t: 1692021600,
    o: 29384.91,
    h: 29593,
    l: 29384.91,
    c: 29534,
    v: 2836.64583,
  },
  {
    t: 1692025200,
    o: 29533.99,
    h: 29672,
    l: 29475.67,
    c: 29599.99,
    v: 2639.23932,
  },
  {
    t: 1692028800,
    o: 29600,
    h: 29695.32,
    l: 29599.99,
    c: 29653.99,
    v: 1535.29311,
  },
  {
    t: 1692032400,
    o: 29653.99,
    h: 29671.96,
    l: 29480.1,
    c: 29487.28,
    v: 1595.00355,
  },
  {
    t: 1692036000,
    o: 29487.29,
    h: 29490,
    l: 29257,
    c: 29330.01,
    v: 3074.89271,
  },
  {
    t: 1692039600,
    o: 29330.01,
    h: 29392.42,
    l: 29322,
    c: 29360.54,
    v: 655.09177,
  },
  {
    t: 1692043200,
    o: 29360.54,
    h: 29400.83,
    l: 29338.12,
    c: 29400.83,
    v: 572.3742,
  },
  {
    t: 1692046800,
    o: 29400.82,
    h: 29408.76,
    l: 29383,
    c: 29405.13,
    v: 335.28567999999996,
  },
  {
    t: 1692050400,
    o: 29405.13,
    h: 29471.31,
    l: 29398,
    c: 29438,
    v: 628.12062,
  },
  {
    t: 1692054000,
    o: 29437.99,
    h: 29441.93,
    l: 29423.01,
    c: 29430.93,
    v: 396.28058999999996,
  },
  {
    t: 1692057600,
    o: 29430.92,
    h: 29454.23,
    l: 29404.71,
    c: 29433.88,
    v: 431.56592,
  },
  {
    t: 1692061200,
    o: 29433.89,
    h: 29450.1,
    l: 29380,
    c: 29411.07,
    v: 697.22646,
  },
  {
    t: 1692064800,
    o: 29411.08,
    h: 29422.43,
    l: 29374.38,
    c: 29383.98,
    v: 1040.7619399999999,
  },
  {
    t: 1692068400,
    o: 29383.98,
    h: 29410,
    l: 29371.99,
    c: 29402.01,
    v: 819.08372,
  },
  {
    t: 1692072000,
    o: 29402,
    h: 29406.01,
    l: 29375.4,
    c: 29378.01,
    v: 883.04985,
  },
  {
    t: 1692075600,
    o: 29378.01,
    h: 29393.19,
    l: 29359.67,
    c: 29369.73,
    v: 621.7916200000001,
  },
  {
    t: 1692079200,
    o: 29369.72,
    h: 29385.95,
    l: 29358.49,
    c: 29374.1,
    v: 974.02456,
  },
  {
    t: 1692082800,
    o: 29374.11,
    h: 29435.02,
    l: 29351.33,
    c: 29408.84,
    v: 1280.44535,
  },
  {
    t: 1692086400,
    o: 29408.83,
    h: 29457.44,
    l: 29401.34,
    c: 29426.15,
    v: 1214.1336900000001,
  },
  {
    t: 1692090000,
    o: 29426.15,
    h: 29450.12,
    l: 29406.7,
    c: 29422.8,
    v: 1118.6548699999998,
  },
  {
    t: 1692093600,
    o: 29422.79,
    h: 29422.96,
    l: 29398,
    c: 29403.74,
    v: 1030.12598,
  },
  {
    t: 1692097200,
    o: 29403.74,
    h: 29404.92,
    l: 29358,
    c: 29366,
    v: 1227.83495,
  },
  {
    t: 1692100800,
    o: 29365.99,
    h: 29403.25,
    l: 29362.97,
    c: 29368.72,
    v: 1043.2078999999999,
  },
  {
    t: 1692104400,
    o: 29368.71,
    h: 29416,
    l: 29366.6,
    c: 29375.44,
    v: 766.5933500000001,
  },
  {
    t: 1692108000,
    o: 29375.44,
    h: 29468.75,
    l: 29337.75,
    c: 29455.76,
    v: 1754.09258,
  },
  {
    t: 1692111600,
    o: 29455.76,
    h: 29499.26,
    l: 29315,
    c: 29332.66,
    v: 1953.8817800000002,
  },
  {
    t: 1692115200,
    o: 29332.67,
    h: 29369.23,
    l: 29260,
    c: 29282.82,
    v: 1810.43176,
  },
  {
    t: 1692118800,
    o: 29282.82,
    h: 29335.84,
    l: 29278.01,
    c: 29335.83,
    v: 629.4121600000001,
  },
  {
    t: 1692122400,
    o: 29335.83,
    h: 29348.11,
    l: 29302.66,
    c: 29323,
    v: 888.53287,
  },
  {
    t: 1692126000,
    o: 29323.01,
    h: 29323.01,
    l: 29059.6,
    c: 29181.73,
    v: 4447.56122,
  },
  {
    t: 1692129600,
    o: 29181.73,
    h: 29234.51,
    l: 29135.81,
    c: 29199,
    v: 1298.33659,
  },
  {
    t: 1692133200,
    o: 29198.99,
    h: 29243.08,
    l: 29178.62,
    c: 29229.94,
    v: 589.2054800000001,
  },
  {
    t: 1692136800,
    o: 29229.93,
    h: 29239.79,
    l: 29182.1,
    c: 29183.62,
    v: 507.54341,
  },
  {
    t: 1692140400,
    o: 29183.61,
    h: 29217.94,
    l: 29176,
    c: 29200,
    v: 476.3789,
  },
  {
    t: 1692144000,
    o: 29200.01,
    h: 29259.85,
    l: 29196.21,
    c: 29224.37,
    v: 638.9313400000001,
  },
  {
    t: 1692147600,
    o: 29224.37,
    h: 29249.02,
    l: 29184.96,
    c: 29245.47,
    v: 955.8899700000001,
  },
  {
    t: 1692151200,
    o: 29245.46,
    h: 29257.46,
    l: 29223.39,
    c: 29255.14,
    v: 1199.36652,
  },
  {
    t: 1692154800,
    o: 29255.14,
    h: 29255.14,
    l: 29223.16,
    c: 29228.51,
    v: 794.90806,
  },
  {
    t: 1692158400,
    o: 29228.51,
    h: 29235.54,
    l: 29204.67,
    c: 29208.37,
    v: 1056.5873299999998,
  },
  {
    t: 1692162000,
    o: 29208.36,
    h: 29209.24,
    l: 29138.16,
    c: 29142.23,
    v: 999.3014600000001,
  },
  {
    t: 1692165600,
    o: 29142.24,
    h: 29195.87,
    l: 29111,
    c: 29193.23,
    v: 1247.19756,
  },
  {
    t: 1692169200,
    o: 29193.22,
    h: 29213.12,
    l: 29177.18,
    c: 29197.67,
    v: 988.9809,
  },
  {
    t: 1692172800,
    o: 29197.67,
    h: 29216.46,
    l: 29161.69,
    c: 29179.96,
    v: 1203.33977,
  },
  {
    t: 1692176400,
    o: 29179.95,
    h: 29179.96,
    l: 29100,
    c: 29167.59,
    v: 1583.01933,
  },
  {
    t: 1692180000,
    o: 29167.6,
    h: 29206.61,
    l: 29164.65,
    c: 29197.33,
    v: 603.02983,
  },
  {
    t: 1692183600,
    o: 29197.34,
    h: 29198,
    l: 29147.94,
    c: 29164.67,
    v: 867.5893500000001,
  },
  {
    t: 1692187200,
    o: 29164.67,
    h: 29211.34,
    l: 29047.09,
    c: 29160,
    v: 2143.21439,
  },
  {
    t: 1692190800,
    o: 29159.99,
    h: 29177.69,
    l: 29073.6,
    c: 29088.62,
    v: 1477.44199,
  },
  {
    t: 1692194400,
    o: 29088.62,
    h: 29165,
    l: 29050.47,
    c: 29159.91,
    v: 1042.06759,
  },
  {
    t: 1692198000,
    o: 29159.92,
    h: 29184.31,
    l: 29100.39,
    c: 29106.55,
    v: 969.73438,
  },
  {
    t: 1692201600,
    o: 29106.55,
    h: 29107.75,
    l: 28956,
    c: 29086.52,
    v: 3244.53118,
  },
  {
    t: 1692205200,
    o: 29086.53,
    h: 29169.35,
    l: 29051.09,
    c: 29168,
    v: 1367.0095999999999,
  },
  {
    t: 1692208800,
    o: 29168,
    h: 29235,
    l: 29108.74,
    c: 29172,
    v: 1294.09391,
  },
  {
    t: 1692212400,
    o: 29171.99,
    h: 29172,
    l: 29106.01,
    c: 29111.7,
    v: 874.2458300000001,
  },
  {
    t: 1692216000,
    o: 29111.69,
    h: 29139.99,
    l: 28840.9,
    c: 28974.01,
    v: 3851.9459399999996,
  },
  {
    t: 1692219600,
    o: 28974.01,
    h: 28974.01,
    l: 28866.76,
    c: 28929.63,
    v: 1523.7776099999999,
  },
  {
    t: 1692223200,
    o: 28929.63,
    h: 28977.77,
    l: 28908,
    c: 28910,
    v: 813.14184,
  },
  {
    t: 1692226800,
    o: 28910.01,
    h: 28910.01,
    l: 28723.08,
    c: 28730.51,
    v: 2257.35286,
  },
  {
    t: 1692230400,
    o: 28730.51,
    h: 28783.48,
    l: 28342,
    c: 28580.85,
    v: 6059.0754,
  },
  {
    t: 1692234000,
    o: 28580.84,
    h: 28635.93,
    l: 28540,
    c: 28632.69,
    v: 1866.66188,
  },
  {
    t: 1692237600,
    o: 28632.7,
    h: 28647.7,
    l: 28570.92,
    c: 28584.35,
    v: 1104.60966,
  },
  {
    t: 1692241200,
    o: 28584.35,
    h: 28698.01,
    l: 28578.01,
    c: 28657.31,
    v: 1108.79301,
  },
  {
    t: 1692244800,
    o: 28657.3,
    h: 28706.31,
    l: 28612.54,
    c: 28649.99,
    v: 987.4776199999999,
  },
  {
    t: 1692248400,
    o: 28649.99,
    h: 28679.65,
    l: 28633.77,
    c: 28679.64,
    v: 569.111,
  },
  {
    t: 1692252000,
    o: 28679.65,
    h: 28697.6,
    l: 28655.92,
    c: 28690.22,
    v: 825.95821,
  },
  {
    t: 1692255600,
    o: 28690.21,
    h: 28690.22,
    l: 28601.87,
    c: 28621.99,
    v: 1447.22509,
  },
  {
    t: 1692259200,
    o: 28622,
    h: 28635.3,
    l: 28546.89,
    c: 28554.01,
    v: 1544.71009,
  },
  {
    t: 1692262800,
    o: 28554.01,
    h: 28620,
    l: 28517.45,
    c: 28593.12,
    v: 1400.0473,
  },
  {
    t: 1692266400,
    o: 28593.12,
    h: 28610.07,
    l: 28496.47,
    c: 28540,
    v: 1471.68509,
  },
  {
    t: 1692270000,
    o: 28540,
    h: 28567.08,
    l: 28483.89,
    c: 28545.11,
    v: 1345.7060700000002,
  },
  {
    t: 1692273600,
    o: 28545.1,
    h: 28573.55,
    l: 28435.26,
    c: 28496.41,
    v: 2261.61697,
  },
  {
    t: 1692277200,
    o: 28496.4,
    h: 28545,
    l: 28381,
    c: 28400,
    v: 2418.9982099999997,
  },
  {
    t: 1692280800,
    o: 28400,
    h: 28460.01,
    l: 28320.11,
    c: 28394.34,
    v: 3073.27676,
  },
  {
    t: 1692284400,
    o: 28394.34,
    h: 28500.6,
    l: 27888,
    c: 27934.02,
    v: 11366.09551,
  },
  {
    t: 1692288000,
    o: 27934.02,
    h: 28089.78,
    l: 27700,
    c: 27937.35,
    v: 8996.816289999999,
  },
  {
    t: 1692291600,
    o: 27937.36,
    h: 28040,
    l: 27936.65,
    c: 27974,
    v: 2158.21248,
  },
  {
    t: 1692295200,
    o: 27973.99,
    h: 28025.08,
    l: 27864.74,
    c: 27879.97,
    v: 1888.6815299999998,
  },
  {
    t: 1692298800,
    o: 27879.98,
    h: 27975.59,
    l: 27858.07,
    c: 27894.01,
    v: 1445.5846999999999,
  },
  {
    t: 1692302400,
    o: 27894,
    h: 27920.23,
    l: 27530,
    c: 27679.57,
    v: 4018.0766399999998,
  },
  {
    t: 1692306000,
    o: 27679.56,
    h: 27737.83,
    l: 25166,
    c: 26253.92,
    v: 17548.88268,
  },
  {
    t: 1692309600,
    o: 26253.93,
    h: 27150,
    l: 25950,
    c: 26801.29,
    v: 16413.95017,
  },
  {
    t: 1692313200,
    o: 26801.3,
    h: 27000,
    l: 26458.67,
    c: 26623.41,
    v: 8950.287970000001,
  },
  {
    t: 1692316800,
    o: 26623.41,
    h: 26832.6,
    l: 26593.86,
    c: 26737.3,
    v: 4030.34231,
  },
  {
    t: 1692320400,
    o: 26737.3,
    h: 26759.96,
    l: 26564,
    c: 26613.49,
    v: 2655.82069,
  },
  {
    t: 1692324000,
    o: 26613.49,
    h: 26684.64,
    l: 26466,
    c: 26482.13,
    v: 2409.52862,
  },
  {
    t: 1692327600,
    o: 26482.13,
    h: 26517.8,
    l: 26321.18,
    c: 26398,
    v: 2651.58892,
  },
  {
    t: 1692331200,
    o: 26397.99,
    h: 26439.58,
    l: 26157.71,
    c: 26267.94,
    v: 3961.3699799999995,
  },
  {
    t: 1692334800,
    o: 26267.93,
    h: 26534.97,
    l: 26262.47,
    c: 26443.76,
    v: 2982.5973700000004,
  },
  {
    t: 1692338400,
    o: 26443.75,
    h: 26610,
    l: 26443.75,
    c: 26502.66,
    v: 3344.3193499999998,
  },
  {
    t: 1692342000,
    o: 26502.67,
    h: 26527.45,
    l: 26423,
    c: 26429.99,
    v: 3091.00241,
  },
  {
    t: 1692345600,
    o: 26430,
    h: 26595.47,
    l: 26420.96,
    c: 26591.69,
    v: 2421.9846500000003,
  },
  {
    t: 1692349200,
    o: 26591.68,
    h: 26609.65,
    l: 26425.82,
    c: 26451.61,
    v: 2614.5114700000004,
  },
  {
    t: 1692352800,
    o: 26451.61,
    h: 26468.67,
    l: 26388,
    c: 26452.08,
    v: 1645.10225,
  },
  {
    t: 1692356400,
    o: 26452.07,
    h: 26514.28,
    l: 26381.6,
    c: 26494,
    v: 1722.18162,
  },
  {
    t: 1692360000,
    o: 26494,
    h: 26500,
    l: 26322,
    c: 26328.02,
    v: 2276.57609,
  },
  {
    t: 1692363600,
    o: 26328.02,
    h: 26384.74,
    l: 26220,
    c: 26241.78,
    v: 3030.39336,
  },
  {
    t: 1692367200,
    o: 26241.78,
    h: 26307.24,
    l: 25981.88,
    c: 26012.08,
    v: 4711.39056,
  },
  {
    t: 1692370800,
    o: 26012.08,
    h: 26252,
    l: 25931.95,
    c: 26002,
    v: 4408.27631,
  },
  {
    t: 1692374400,
    o: 26001.99,
    h: 26141.85,
    l: 25769.04,
    c: 25904.91,
    v: 3395.29257,
  },
  {
    t: 1692378000,
    o: 25904.9,
    h: 26114,
    l: 25619,
    c: 26012.02,
    v: 7035.182580000001,
  },
  {
    t: 1692381600,
    o: 26012.01,
    h: 26313.33,
    l: 25921,
    c: 26124.01,
    v: 3566.04182,
  },
  {
    t: 1692385200,
    o: 26124.01,
    h: 26145.01,
    l: 25992,
    c: 26065.01,
    v: 2492.75497,
  },
  {
    t: 1692388800,
    o: 26065.01,
    h: 26243.8,
    l: 26049.02,
    c: 26083.78,
    v: 2046.9993399999998,
  },
  {
    t: 1692392400,
    o: 26083.77,
    h: 26177.7,
    l: 26062.74,
    c: 26062.75,
    v: 1006.3682399999999,
  },
  {
    t: 1692396000,
    o: 26062.75,
    h: 26120.44,
    l: 26040.14,
    c: 26071.99,
    v: 1146.60059,
  },
  {
    t: 1692399600,
    o: 26071.99,
    h: 26094.49,
    l: 26006.01,
    c: 26054,
    v: 1144.43104,
  },
  {
    t: 1692403200,
    o: 26054,
    h: 26154.57,
    l: 26017.68,
    c: 26099.09,
    v: 1070.80588,
  },
  {
    t: 1692406800,
    o: 26099.09,
    h: 26100,
    l: 26014,
    c: 26016.01,
    v: 1039.64183,
  },
  {
    t: 1692410400,
    o: 26016.01,
    h: 26066.6,
    l: 26000.15,
    c: 26002.01,
    v: 970.28205,
  },
  {
    t: 1692414000,
    o: 26002.01,
    h: 26002.01,
    l: 25924,
    c: 25967.51,
    v: 917.7342799999999,
  },
  {
    t: 1692417600,
    o: 25967.51,
    h: 26009.18,
    l: 25922,
    c: 25988.76,
    v: 771.8715500000001,
  },
  {
    t: 1692421200,
    o: 25988.76,
    h: 25994.67,
    l: 25875.01,
    c: 25884.49,
    v: 1092.01294,
  },
  {
    t: 1692424800,
    o: 25884.48,
    h: 25929.02,
    l: 25825.96,
    c: 25836.28,
    v: 1092.94363,
  },
  {
    t: 1692428400,
    o: 25836.27,
    h: 25920,
    l: 25801.09,
    c: 25917.99,
    v: 1497.93123,
  },
  {
    t: 1692432000,
    o: 25918,
    h: 25999.01,
    l: 25894.89,
    c: 25932.97,
    v: 1428.93159,
  },
  {
    t: 1692435600,
    o: 25932.98,
    h: 25965.99,
    l: 25884,
    c: 25945.99,
    v: 1096.90992,
  },
  {
    t: 1692439200,
    o: 25945.99,
    h: 25959.7,
    l: 25891.08,
    c: 25908.82,
    v: 849.26552,
  },
  {
    t: 1692442800,
    o: 25908.82,
    h: 25943.75,
    l: 25892.94,
    c: 25900.08,
    v: 648.84907,
  },
  {
    t: 1692446400,
    o: 25900.07,
    h: 25926,
    l: 25872.06,
    c: 25914.37,
    v: 908.20519,
  },
  {
    t: 1692450000,
    o: 25914.38,
    h: 25981.53,
    l: 25908,
    c: 25925.24,
    v: 978.21335,
  },
  {
    t: 1692453600,
    o: 25925.24,
    h: 26051.36,
    l: 25920.12,
    c: 25975,
    v: 1586.87295,
  },
  {
    t: 1692457200,
    o: 25975.01,
    h: 26226.04,
    l: 25957.29,
    c: 26216.01,
    v: 2633.0815199999997,
  },
  {
    t: 1692460800,
    o: 26216.01,
    h: 26281,
    l: 26117,
    c: 26253.3,
    v: 2206.91551,
  },
  {
    t: 1692464400,
    o: 26253.3,
    h: 26253.31,
    l: 26072.29,
    c: 26086.9,
    v: 1368.5995599999999,
  },
  {
    t: 1692468000,
    o: 26086.9,
    h: 26138,
    l: 26026.96,
    c: 26126.44,
    v: 972.91371,
  },
  {
    t: 1692471600,
    o: 26126.44,
    h: 26159.84,
    l: 26095.36,
    c: 26125.62,
    v: 555.81719,
  },
  {
    t: 1692475200,
    o: 26125.61,
    h: 26156,
    l: 26033.16,
    c: 26056.78,
    v: 1256.03637,
  },
  {
    t: 1692478800,
    o: 26056.78,
    h: 26096.51,
    l: 26050.1,
    c: 26084.96,
    v: 442.28545999999994,
  },
  {
    t: 1692482400,
    o: 26084.97,
    h: 26089.34,
    l: 26049.65,
    c: 26054,
    v: 366.63176999999996,
  },
  {
    t: 1692486000,
    o: 26054,
    h: 26124.99,
    l: 26053.21,
    c: 26100.01,
    v: 408.00136,
  },
  {
    t: 1692489600,
    o: 26100.01,
    h: 26188.09,
    l: 26081.32,
    c: 26181.68,
    v: 580.30106,
  },
  {
    t: 1692493200,
    o: 26181.67,
    h: 26207.88,
    l: 26046.4,
    c: 26072.45,
    v: 858.02306,
  },
  {
    t: 1692496800,
    o: 26072.45,
    h: 26107.81,
    l: 26048.36,
    c: 26094,
    v: 478.87214,
  },
  {
    t: 1692500400,
    o: 26093.99,
    h: 26143.04,
    l: 26093.99,
    c: 26105.24,
    v: 744.4721099999999,
  },
  {
    t: 1692504000,
    o: 26105.23,
    h: 26131.21,
    l: 26101.25,
    c: 26121.47,
    v: 850.2788700000001,
  },
  {
    t: 1692507600,
    o: 26121.46,
    h: 26160.16,
    l: 26067.7,
    c: 26092,
    v: 893.25496,
  },
  {
    t: 1692511200,
    o: 26092.01,
    h: 26092.01,
    l: 26047.41,
    c: 26063.99,
    v: 682.2710000000001,
  },
  {
    t: 1692514800,
    o: 26064,
    h: 26092,
    l: 26060.29,
    c: 26088.44,
    v: 489.50237000000004,
  },
  {
    t: 1692518400,
    o: 26088.44,
    h: 26112,
    l: 26058.98,
    c: 26112,
    v: 496.83759999999995,
  },
  {
    t: 1692522000,
    o: 26112,
    h: 26144.89,
    l: 26093.23,
    c: 26144,
    v: 784.17073,
  },
  {
    t: 1692525600,
    o: 26144,
    h: 26157.61,
    l: 26120.16,
    c: 26148.05,
    v: 774.7699700000001,
  },
  {
    t: 1692529200,
    o: 26148.05,
    h: 26198.72,
    l: 26131.71,
    c: 26176.7,
    v: 980.8324399999999,
  },
  {
    t: 1692532800,
    o: 26176.69,
    h: 26197.62,
    l: 26135.56,
    c: 26183.72,
    v: 596.19702,
  },
  {
    t: 1692536400,
    o: 26183.71,
    h: 26217.52,
    l: 26128.02,
    c: 26135.9,
    v: 884.63924,
  },
  {
    t: 1692540000,
    o: 26135.9,
    h: 26149.43,
    l: 26062,
    c: 26062.87,
    v: 1091.8505300000002,
  },
  {
    t: 1692543600,
    o: 26062.86,
    h: 26138.25,
    l: 25971.05,
    c: 26106.83,
    v: 1854.5572800000002,
  },
  {
    t: 1692547200,
    o: 26106.83,
    h: 26123.99,
    l: 26002.51,
    c: 26053.01,
    v: 734.02036,
  },
  {
    t: 1692550800,
    o: 26053.01,
    h: 26162.34,
    l: 26028.19,
    c: 26124.18,
    v: 769.7070699999999,
  },
  {
    t: 1692554400,
    o: 26124.18,
    h: 26150,
    l: 26105.89,
    c: 26113.81,
    v: 602.3951199999999,
  },
  {
    t: 1692558000,
    o: 26113.82,
    h: 26139.77,
    l: 26108.97,
    c: 26137.25,
    v: 349.88083,
  },
  {
    t: 1692561600,
    o: 26137.25,
    h: 26299,
    l: 26137.25,
    c: 26232.98,
    v: 1673.76825,
  },
  {
    t: 1692565200,
    o: 26232.99,
    h: 26243.48,
    l: 26143.59,
    c: 26176.86,
    v: 690.3993800000001,
  },
  {
    t: 1692568800,
    o: 26176.85,
    h: 26225.19,
    l: 26176.85,
    c: 26209.46,
    v: 444.89842,
  },
  {
    t: 1692572400,
    o: 26209.47,
    h: 26209.47,
    l: 26146.95,
    c: 26189.99,
    v: 751.01228,
  },
  {
    t: 1692576000,
    o: 26190,
    h: 26195.12,
    l: 26120.01,
    c: 26142,
    v: 635.7416800000001,
  },
  {
    t: 1692579600,
    o: 26142,
    h: 26168,
    l: 26085.01,
    c: 26146.14,
    v: 974.27169,
  },
  {
    t: 1692583200,
    o: 26146.14,
    h: 26161.23,
    l: 26080.22,
    c: 26097.38,
    v: 645.45415,
  },
  {
    t: 1692586800,
    o: 26097.38,
    h: 26111.76,
    l: 26070,
    c: 26090.66,
    v: 759.58335,
  },
  {
    t: 1692590400,
    o: 26090.66,
    h: 26090.67,
    l: 26045,
    c: 26059.02,
    v: 676.6602800000001,
  },
  {
    t: 1692594000,
    o: 26059.02,
    h: 26189.04,
    l: 26048,
    c: 26104.54,
    v: 1321.50058,
  },
  {
    t: 1692597600,
    o: 26104.54,
    h: 26120,
    l: 26030.41,
    c: 26032,
    v: 818.24431,
  },
  {
    t: 1692601200,
    o: 26032.01,
    h: 26051.49,
    l: 26000,
    c: 26023.34,
    v: 1274.84087,
  },
  {
    t: 1692604800,
    o: 26023.33,
    h: 26070,
    l: 25983.88,
    c: 26059.99,
    v: 989.00367,
  },
  {
    t: 1692608400,
    o: 26059.99,
    h: 26088.32,
    l: 26002,
    c: 26028,
    v: 1067.73223,
  },
  {
    t: 1692612000,
    o: 26028.01,
    h: 26028.01,
    l: 25880.2,
    c: 25944.01,
    v: 2648.53102,
  },
  {
    t: 1692615600,
    o: 25944.01,
    h: 26044.27,
    l: 25931.53,
    c: 26031.6,
    v: 1098.55808,
  },
  {
    t: 1692619200,
    o: 26031.61,
    h: 26085.15,
    l: 25994,
    c: 26004,
    v: 1293.92281,
  },
  {
    t: 1692622800,
    o: 26004,
    h: 26144.09,
    l: 25982.84,
    c: 26129.7,
    v: 1440.57449,
  },
  {
    t: 1692626400,
    o: 26129.69,
    h: 26198.89,
    l: 26058,
    c: 26108.51,
    v: 1850.2302399999999,
  },
  {
    t: 1692630000,
    o: 26108.52,
    h: 26134.19,
    l: 25812,
    c: 25939.59,
    v: 4704.060229999999,
  },
  {
    t: 1692633600,
    o: 25939.59,
    h: 26048.47,
    l: 25937.6,
    c: 26015.43,
    v: 1625.8346499999998,
  },
  {
    t: 1692637200,
    o: 26015.42,
    h: 26075.85,
    l: 26002.08,
    c: 26050.7,
    v: 1072.00248,
  },
  {
    t: 1692640800,
    o: 26050.7,
    h: 26111,
    l: 26026.5,
    c: 26034.18,
    v: 917.00433,
  },
  {
    t: 1692644400,
    o: 26034.18,
    h: 26164,
    l: 26034.17,
    c: 26097.74,
    v: 1380.61472,
  },
  {
    t: 1692648000,
    o: 26097.73,
    h: 26154.79,
    l: 26091.2,
    c: 26105.6,
    v: 950.4433,
  },
  {
    t: 1692651600,
    o: 26105.6,
    h: 26258.42,
    l: 26082.25,
    c: 26196.01,
    v: 1150.2321200000001,
  },
  {
    t: 1692655200,
    o: 26196.01,
    h: 26196.01,
    l: 26132,
    c: 26132,
    v: 585.5736899999999,
  },
  {
    t: 1692658800,
    o: 26132,
    h: 26152,
    l: 26114.69,
    c: 26126.92,
    v: 386.62736,
  },
  {
    t: 1692662400,
    o: 26126.92,
    h: 26139.42,
    l: 26094.14,
    c: 26100.11,
    v: 564.00731,
  },
  {
    t: 1692666000,
    o: 26100.1,
    h: 26107.82,
    l: 26050,
    c: 26050,
    v: 838.8050099999999,
  },
  {
    t: 1692669600,
    o: 26050,
    h: 26069.99,
    l: 26044.03,
    c: 26065.61,
    v: 490.09427,
  },
  {
    t: 1692673200,
    o: 26065.62,
    h: 26075.2,
    l: 26025.54,
    c: 26055.99,
    v: 866.9232099999999,
  },
  {
    t: 1692676800,
    o: 26056,
    h: 26056.21,
    l: 26003.66,
    c: 26015.22,
    v: 775.83007,
  },
  {
    t: 1692680400,
    o: 26015.23,
    h: 26051.56,
    l: 26000,
    c: 26041.39,
    v: 680.70813,
  },
  {
    t: 1692684000,
    o: 26041.39,
    h: 26093.94,
    l: 26027.24,
    c: 26069.99,
    v: 592.8237200000001,
  },
  {
    t: 1692687600,
    o: 26069.99,
    h: 26117.28,
    l: 26062,
    c: 26106.69,
    v: 656.31548,
  },
  {
    t: 1692691200,
    o: 26106.69,
    h: 26112.44,
    l: 26052,
    c: 26061.72,
    v: 935.2772199999999,
  },
  {
    t: 1692694800,
    o: 26061.73,
    h: 26086.38,
    l: 26034.25,
    c: 26048.07,
    v: 574.1100299999999,
  },
  {
    t: 1692698400,
    o: 26048.06,
    h: 26056,
    l: 26030,
    c: 26047.48,
    v: 547.08922,
  },
  {
    t: 1692702000,
    o: 26047.48,
    h: 26066,
    l: 26027.22,
    c: 26049.64,
    v: 788.43624,
  },
  {
    t: 1692705600,
    o: 26049.63,
    h: 26100.23,
    l: 26044.94,
    c: 26044.94,
    v: 758.08081,
  },
  {
    t: 1692709200,
    o: 26044.94,
    h: 26059,
    l: 25969.31,
    c: 25998.93,
    v: 1443.50715,
  },
  {
    t: 1692712800,
    o: 25998.94,
    h: 26059,
    l: 25945.66,
    c: 25994.21,
    v: 1507.16702,
  },
  {
    t: 1692716400,
    o: 25994.2,
    h: 26025,
    l: 25914.03,
    c: 25936.61,
    v: 1374.78354,
  },
  {
    t: 1692720000,
    o: 25936.62,
    h: 26054.73,
    l: 25796.31,
    c: 26020.01,
    v: 3966.51238,
  },
  {
    t: 1692723600,
    o: 26020.01,
    h: 26033.04,
    l: 25726.29,
    c: 25832.42,
    v: 3276.2756899999995,
  },
  {
    t: 1692727200,
    o: 25832.41,
    h: 26008.64,
    l: 25770.14,
    c: 25905.6,
    v: 1817.3276999999998,
  },
  {
    t: 1692730800,
    o: 25905.6,
    h: 25963.99,
    l: 25773.96,
    c: 25779.99,
    v: 1191.08715,
  },
  {
    t: 1692734400,
    o: 25780,
    h: 25870.01,
    l: 25779.99,
    c: 25864.01,
    v: 899.4016799999999,
  },
  {
    t: 1692738000,
    o: 25864,
    h: 25903.78,
    l: 25300,
    c: 25669.29,
    v: 5609.68716,
  },
  {
    t: 1692741600,
    o: 25669.3,
    h: 25856.63,
    l: 25643.46,
    c: 25807.99,
    v: 1801.76946,
  },
  {
    t: 1692745200,
    o: 25808,
    h: 26070.04,
    l: 25784.69,
    c: 26056,
    v: 2291.04723,
  },
  {
    t: 1692748800,
    o: 26055.99,
    h: 26187.99,
    l: 26030.56,
    c: 26040.38,
    v: 2447.7722599999997,
  },
  {
    t: 1692752400,
    o: 26040.38,
    h: 26081.64,
    l: 25994.12,
    c: 26049.41,
    v: 728.8126,
  },
  {
    t: 1692756000,
    o: 26049.41,
    h: 26091.75,
    l: 26033,
    c: 26044.42,
    v: 795.45411,
  },
  {
    t: 1692759600,
    o: 26044.41,
    h: 26078.01,
    l: 25990.15,
    c: 26006.32,
    v: 764.41941,
  },
  {
    t: 1692763200,
    o: 26006.32,
    h: 26062,
    l: 25976.72,
    c: 26050.01,
    v: 903.41765,
  },
  {
    t: 1692766800,
    o: 26050.02,
    h: 26053.53,
    l: 25991.1,
    c: 26042.01,
    v: 967.31063,
  },
  {
    t: 1692770400,
    o: 26042,
    h: 26183.91,
    l: 26038.31,
    c: 26064,
    v: 1927.9712499999998,
  },
  {
    t: 1692774000,
    o: 26063.99,
    h: 26132.47,
    l: 26014.7,
    c: 26057.13,
    v: 1267.75092,
  },
  {
    t: 1692777600,
    o: 26057.12,
    h: 26096,
    l: 26027.49,
    c: 26037.99,
    v: 643.9755,
  },
  {
    t: 1692781200,
    o: 26037.99,
    h: 26057.81,
    l: 26015.45,
    c: 26042.01,
    v: 785.10753,
  },
  {
    t: 1692784800,
    o: 26042.01,
    h: 26042.01,
    l: 25953.92,
    c: 25965.99,
    v: 976.77909,
  },
  {
    t: 1692788400,
    o: 25965.99,
    h: 25966.84,
    l: 25902.62,
    c: 25939.49,
    v: 1289.8750100000002,
  },
  {
    t: 1692792000,
    o: 25939.49,
    h: 25962,
    l: 25842,
    c: 25844.01,
    v: 1283.43795,
  },
  {
    t: 1692795600,
    o: 25844,
    h: 26038.88,
    l: 25812.82,
    c: 26018.22,
    v: 1731.9030200000002,
  },
  {
    t: 1692799200,
    o: 26018.22,
    h: 26136,
    l: 25924.84,
    c: 26041.99,
    v: 2313.96862,
  },
  {
    t: 1692802800,
    o: 26041.98,
    h: 26185,
    l: 26027.04,
    c: 26144,
    v: 2889.92397,
  },
  {
    t: 1692806400,
    o: 26143.99,
    h: 26480.97,
    l: 26127.24,
    c: 26396,
    v: 6146.05027,
  },
  {
    t: 1692810000,
    o: 26396.01,
    h: 26605,
    l: 26372,
    c: 26539.34,
    v: 3706.42638,
  },
  {
    t: 1692813600,
    o: 26539.35,
    h: 26559.68,
    l: 26433.47,
    c: 26462.97,
    v: 1972.36717,
  },
  {
    t: 1692817200,
    o: 26462.96,
    h: 26819.27,
    l: 26445.38,
    c: 26632,
    v: 3545.99837,
  },
  {
    t: 1692820800,
    o: 26632.01,
    h: 26723.99,
    l: 26538.71,
    c: 26593.59,
    v: 2278.6692199999998,
  },
  {
    t: 1692824400,
    o: 26593.6,
    h: 26623.01,
    l: 26222.43,
    c: 26320.02,
    v: 2180.34295,
  },
  {
    t: 1692828000,
    o: 26320.01,
    h: 26489.66,
    l: 26300.01,
    c: 26456,
    v: 1309.3490699999998,
  },
  {
    t: 1692831600,
    o: 26455.99,
    h: 26529.99,
    l: 26406.12,
    c: 26432.72,
    v: 1166.61683,
  },
  {
    t: 1692835200,
    o: 26432.71,
    h: 26547.89,
    l: 26426,
    c: 26513.66,
    v: 1200.13957,
  },
  {
    t: 1692838800,
    o: 26513.66,
    h: 26529.98,
    l: 26348,
    c: 26348.01,
    v: 1382.6999999999998,
  },
  {
    t: 1692842400,
    o: 26348,
    h: 26417.54,
    l: 26345.76,
    c: 26374.5,
    v: 802.9770100000001,
  },
  {
    t: 1692846000,
    o: 26374.49,
    h: 26450.84,
    l: 26374.49,
    c: 26442.01,
    v: 699.80616,
  },
  {
    t: 1692849600,
    o: 26442.01,
    h: 26479.24,
    l: 26413.72,
    c: 26456.13,
    v: 627.78905,
  },
  {
    t: 1692853200,
    o: 26456.14,
    h: 26485.98,
    l: 26408.9,
    c: 26421.67,
    v: 780.9729,
  },
  {
    t: 1692856800,
    o: 26421.67,
    h: 26516,
    l: 26384,
    c: 26447.35,
    v: 1195.50333,
  },
  {
    t: 1692860400,
    o: 26447.35,
    h: 26495.8,
    l: 26431.93,
    c: 26477.38,
    v: 990.90777,
  },
  {
    t: 1692864000,
    o: 26477.39,
    h: 26517,
    l: 26425.03,
    c: 26494.87,
    v: 802.5677599999999,
  },
  {
    t: 1692867600,
    o: 26494.88,
    h: 26577.87,
    l: 26431.13,
    c: 26440.75,
    v: 1456.32747,
  },
  {
    t: 1692871200,
    o: 26440.75,
    h: 26478,
    l: 26409.4,
    c: 26475.98,
    v: 716.9649400000001,
  },
  {
    t: 1692874800,
    o: 26475.99,
    h: 26541.64,
    l: 26444.01,
    c: 26444.02,
    v: 965.1268,
  },
  {
    t: 1692878400,
    o: 26444.02,
    h: 26444.02,
    l: 26381.6,
    c: 26390.08,
    v: 1116.77374,
  },
  {
    t: 1692882000,
    o: 26390.08,
    h: 26425.01,
    l: 26291.39,
    c: 26356.52,
    v: 1498.51227,
  },
  {
    t: 1692885600,
    o: 26356.52,
    h: 26374,
    l: 26073,
    c: 26116.05,
    v: 3376.3941099999997,
  },
  {
    t: 1692889200,
    o: 26116.06,
    h: 26168,
    l: 25990.72,
    c: 25996.34,
    v: 2763.90573,
  },
  {
    t: 1692892800,
    o: 25996.35,
    h: 26163,
    l: 25994,
    c: 26115,
    v: 1659.23355,
  },
  {
    t: 1692896400,
    o: 26114.99,
    h: 26120,
    l: 25864,
    c: 26059.99,
    v: 2470.3193499999998,
  },
  {
    t: 1692900000,
    o: 26059.99,
    h: 26074.22,
    l: 25976.74,
    c: 26021.25,
    v: 1207.40905,
  },
  {
    t: 1692903600,
    o: 26021.26,
    h: 26137.99,
    l: 26007.19,
    c: 26039.44,
    v: 1101.4264400000002,
  },
  {
    t: 1692907200,
    o: 26039.44,
    h: 26106.6,
    l: 25936.01,
    c: 26031.99,
    v: 1326.22896,
  },
  {
    t: 1692910800,
    o: 26031.98,
    h: 26095.85,
    l: 26021.46,
    c: 26095,
    v: 626.12112,
  },
  {
    t: 1692914400,
    o: 26095,
    h: 26132.71,
    l: 26088.03,
    c: 26119.71,
    v: 555.33443,
  },
  {
    t: 1692918000,
    o: 26119.71,
    h: 26207.15,
    l: 26066,
    c: 26180.05,
    v: 881.77965,
  },
  {
    t: 1692921600,
    o: 26180.05,
    h: 26187.34,
    l: 26120.94,
    c: 26138.63,
    v: 633.9993,
  },
  {
    t: 1692925200,
    o: 26138.64,
    h: 26152.83,
    l: 26090.94,
    c: 26148.76,
    v: 667.46835,
  },
  {
    t: 1692928800,
    o: 26148.76,
    h: 26178.92,
    l: 26121.71,
    c: 26130.96,
    v: 493.87203000000005,
  },
  {
    t: 1692932400,
    o: 26130.95,
    h: 26141.82,
    l: 26011.41,
    c: 26106,
    v: 981.7330199999999,
  },
  {
    t: 1692936000,
    o: 26106,
    h: 26111.11,
    l: 26026,
    c: 26026.08,
    v: 623.28978,
  },
  {
    t: 1692939600,
    o: 26026.08,
    h: 26079.14,
    l: 26007.63,
    c: 26059.99,
    v: 531.19469,
  },
  {
    t: 1692943200,
    o: 26059.99,
    h: 26060,
    l: 25990.49,
    c: 26015.37,
    v: 1029.4622599999998,
  },
  {
    t: 1692946800,
    o: 26015.38,
    h: 26064.99,
    l: 25974.22,
    c: 25980.5,
    v: 1252.0202100000001,
  },
  {
    t: 1692950400,
    o: 25980.5,
    h: 26108,
    l: 25980.49,
    c: 26093.51,
    v: 968.3389699999999,
  },
  {
    t: 1692954000,
    o: 26093.5,
    h: 26128.75,
    l: 26078.67,
    c: 26079.98,
    v: 906.00622,
  },
  {
    t: 1692957600,
    o: 26079.99,
    h: 26111.88,
    l: 26074.15,
    c: 26088.01,
    v: 575.56778,
  },
  {
    t: 1692961200,
    o: 26088,
    h: 26110.7,
    l: 26083.22,
    c: 26096.4,
    v: 474.87025000000006,
  },
  {
    t: 1692964800,
    o: 26096.41,
    h: 26145,
    l: 26096.4,
    c: 26108.69,
    v: 560.12426,
  },
  {
    t: 1692968400,
    o: 26108.7,
    h: 26237.92,
    l: 26060.01,
    c: 26068.33,
    v: 1907.89251,
  },
  {
    t: 1692972000,
    o: 26068.33,
    h: 26314.05,
    l: 25777.15,
    c: 25833.58,
    v: 5933.25892,
  },
  {
    t: 1692975600,
    o: 25833.59,
    h: 26056.33,
    l: 25812,
    c: 25932.01,
    v: 2978.74064,
  },
  {
    t: 1692979200,
    o: 25932.01,
    h: 26030.9,
    l: 25902.15,
    c: 25935.33,
    v: 1362.7979400000002,
  },
  {
    t: 1692982800,
    o: 25935.34,
    h: 25983.65,
    l: 25832.25,
    c: 25890,
    v: 1537.1324399999999,
  },
  {
    t: 1692986400,
    o: 25889.99,
    h: 26066.62,
    l: 25872.24,
    c: 26052.01,
    v: 1171.8164499999998,
  },
  {
    t: 1692990000,
    o: 26052.01,
    h: 26061.6,
    l: 25950,
    c: 25972.36,
    v: 851.72519,
  },
  {
    t: 1692993600,
    o: 25972.37,
    h: 26082,
    l: 25957.4,
    c: 26060.84,
    v: 762.6006299999999,
  },
  {
    t: 1692997200,
    o: 26060.84,
    h: 26083.74,
    l: 26030,
    c: 26081.86,
    v: 633.9308100000001,
  },
  {
    t: 1693000800,
    o: 26081.87,
    h: 26085.2,
    l: 26038.01,
    c: 26053.98,
    v: 531.04487,
  },
  {
    t: 1693004400,
    o: 26053.98,
    h: 26063.47,
    l: 26034.81,
    c: 26060.01,
    v: 384.4502,
  },
  {
    t: 1693008000,
    o: 26060,
    h: 26097.1,
    l: 26027.2,
    c: 26040.27,
    v: 345.99422,
  },
  {
    t: 1693011600,
    o: 26040.26,
    h: 26077.1,
    l: 26034.09,
    c: 26046,
    v: 266.43219999999997,
  },
  {
    t: 1693015200,
    o: 26046.01,
    h: 26125.77,
    l: 26046,
    c: 26076.01,
    v: 523.59749,
  },
  {
    t: 1693018800,
    o: 26076.01,
    h: 26100,
    l: 26064.24,
    c: 26087.15,
    v: 448.29605999999995,
  },
  {
    t: 1693022400,
    o: 26087.16,
    h: 26088,
    l: 26064.43,
    c: 26076,
    v: 471.46967,
  },
  {
    t: 1693026000,
    o: 26076,
    h: 26076.67,
    l: 26052.78,
    c: 26062,
    v: 264.46708,
  },
  {
    t: 1693029600,
    o: 26062.01,
    h: 26079.88,
    l: 26050.63,
    c: 26079.88,
    v: 626.74739,
  },
  {
    t: 1693033200,
    o: 26079.88,
    h: 26083.89,
    l: 26048,
    c: 26050.81,
    v: 826.24047,
  },
  {
    t: 1693036800,
    o: 26050.82,
    h: 26060,
    l: 26001.83,
    c: 26041.9,
    v: 577.6433400000001,
  },
  {
    t: 1693040400,
    o: 26041.9,
    h: 26043.24,
    l: 26007.03,
    c: 26015.41,
    v: 306.9076,
  },
  {
    t: 1693044000,
    o: 26015.41,
    h: 26035.15,
    l: 26013.44,
    c: 26035.15,
    v: 274.93035,
  },
  {
    t: 1693047600,
    o: 26035.15,
    h: 26054.8,
    l: 26035.14,
    c: 26045.87,
    v: 385.93244,
  },
  {
    t: 1693051200,
    o: 26045.86,
    h: 26046.88,
    l: 25985.92,
    c: 26029.37,
    v: 501.7288,
  },
  {
    t: 1693054800,
    o: 26029.36,
    h: 26044.2,
    l: 26029.36,
    c: 26044.19,
    v: 271.97871,
  },
  {
    t: 1693058400,
    o: 26044.19,
    h: 26073.01,
    l: 26030.75,
    c: 26068.01,
    v: 417.86223,
  },
  {
    t: 1693062000,
    o: 26068.01,
    h: 26078,
    l: 26050.01,
    c: 26054,
    v: 498.28771,
  },
  {
    t: 1693065600,
    o: 26054,
    h: 26060,
    l: 26013.59,
    c: 26041.93,
    v: 624.2460599999999,
  },
  {
    t: 1693069200,
    o: 26041.92,
    h: 26041.93,
    l: 26004.77,
    c: 26035.04,
    v: 481.46794,
  },
  {
    t: 1693072800,
    o: 26035.05,
    h: 26035.05,
    l: 26006,
    c: 26019.99,
    v: 255.69735000000003,
  },
  {
    t: 1693076400,
    o: 26020,
    h: 26039.49,
    l: 26019.25,
    c: 26034.21,
    v: 264.02529,
  },
  {
    t: 1693080000,
    o: 26034.22,
    h: 26043.99,
    l: 26026,
    c: 26032.37,
    v: 253.00081,
  },
  {
    t: 1693083600,
    o: 26032.38,
    h: 26047.62,
    l: 26017.73,
    c: 26042.02,
    v: 330.65708000000006,
  },
  {
    t: 1693087200,
    o: 26042.02,
    h: 26047.16,
    l: 26030.2,
    c: 26030.2,
    v: 269.48024,
  },
  {
    t: 1693090800,
    o: 26030.2,
    h: 26031.15,
    l: 25988.2,
    c: 26017.37,
    v: 535.1326899999999,
  },
  {
    t: 1693094400,
    o: 26017.38,
    h: 26030.55,
    l: 26000,
    c: 26000.01,
    v: 278.66400999999996,
  },
  {
    t: 1693098000,
    o: 26000,
    h: 26011.72,
    l: 25966.11,
    c: 25997.74,
    v: 790.06538,
  },
  {
    t: 1693101600,
    o: 25997.74,
    h: 26020.07,
    l: 25994.96,
    c: 26000.06,
    v: 435.28044,
  },
  {
    t: 1693105200,
    o: 26000.05,
    h: 26022.88,
    l: 26000.05,
    c: 26021.96,
    v: 453.14119999999997,
  },
  {
    t: 1693108800,
    o: 26021.96,
    h: 26038.27,
    l: 26021.96,
    c: 26037.81,
    v: 223.8968,
  },
  {
    t: 1693112400,
    o: 26037.82,
    h: 26044.98,
    l: 26029.88,
    c: 26033.52,
    v: 335.40364,
  },
  {
    t: 1693116000,
    o: 26033.52,
    h: 26050,
    l: 26024.46,
    c: 26045.97,
    v: 457.0204,
  },
  {
    t: 1693119600,
    o: 26045.98,
    h: 26055.54,
    l: 26043.61,
    c: 26043.61,
    v: 519.73665,
  },
  {
    t: 1693123200,
    o: 26043.61,
    h: 26068.33,
    l: 26033.78,
    c: 26063.09,
    v: 675.78165,
  },
  {
    t: 1693126800,
    o: 26063.1,
    h: 26078.13,
    l: 26050.73,
    c: 26078.13,
    v: 644.49339,
  },
  {
    t: 1693130400,
    o: 26078.12,
    h: 26095.6,
    l: 26076.83,
    c: 26082.77,
    v: 700.00749,
  },
  {
    t: 1693134000,
    o: 26082.78,
    h: 26082.78,
    l: 26057.25,
    c: 26060.44,
    v: 345.66958999999997,
  },
  {
    t: 1693137600,
    o: 26060.44,
    h: 26071.91,
    l: 26052.8,
    c: 26071.66,
    v: 292.19120999999996,
  },
  {
    t: 1693141200,
    o: 26071.67,
    h: 26090.26,
    l: 26066.56,
    c: 26079.99,
    v: 301.249,
  },
  {
    t: 1693144800,
    o: 26079.99,
    h: 26182.23,
    l: 26079.98,
    c: 26145.28,
    v: 1219.44221,
  },
  {
    t: 1693148400,
    o: 26145.27,
    h: 26167.52,
    l: 26115.77,
    c: 26122.15,
    v: 764.5953,
  },
  {
    t: 1693152000,
    o: 26122.14,
    h: 26166.09,
    l: 26122.14,
    c: 26166.08,
    v: 782.82489,
  },
  {
    t: 1693155600,
    o: 26166.08,
    h: 26179,
    l: 26044.3,
    c: 26065.08,
    v: 693.6766399999999,
  },
  {
    t: 1693159200,
    o: 26065.08,
    h: 26111.95,
    l: 26037.27,
    c: 26104.12,
    v: 536.0881400000001,
  },
  {
    t: 1693162800,
    o: 26104.13,
    h: 26164.29,
    l: 26096.3,
    c: 26125.49,
    v: 517.4773700000001,
  },
  {
    t: 1693166400,
    o: 26125.48,
    h: 26128.1,
    l: 26082.72,
    c: 26091.49,
    v: 265.81451,
  },
  {
    t: 1693170000,
    o: 26091.5,
    h: 26133.71,
    l: 26040.17,
    c: 26067.86,
    v: 326.13381,
  },
  {
    t: 1693173600,
    o: 26067.87,
    h: 26090.28,
    l: 26045.35,
    c: 26089.29,
    v: 315.81239000000005,
  },
  {
    t: 1693177200,
    o: 26089.29,
    h: 26109,
    l: 26083.86,
    c: 26101.77,
    v: 225.17605,
  },
  {
    t: 1693180800,
    o: 26101.78,
    h: 26105,
    l: 25986.41,
    c: 26023.51,
    v: 1227.4471,
  },
  {
    t: 1693184400,
    o: 26023.51,
    h: 26063.39,
    l: 25993.58,
    c: 26028.01,
    v: 857.5190399999999,
  },
  {
    t: 1693188000,
    o: 26028.02,
    h: 26056.93,
    l: 26019.53,
    c: 26030.5,
    v: 481.96317,
  },
  {
    t: 1693191600,
    o: 26030.49,
    h: 26050.75,
    l: 26012.98,
    c: 26012.98,
    v: 742.29889,
  },
  {
    t: 1693195200,
    o: 26012.98,
    h: 26038.83,
    l: 26009.37,
    c: 26026.61,
    v: 514.7467,
  },
  {
    t: 1693198800,
    o: 26026.62,
    h: 26031.72,
    l: 25872.05,
    c: 25963.02,
    v: 1890.7722600000002,
  },
  {
    t: 1693202400,
    o: 25963.01,
    h: 25987.67,
    l: 25926.37,
    c: 25946.26,
    v: 618.4598599999999,
  },
  {
    t: 1693206000,
    o: 25946.26,
    h: 25949.72,
    l: 25887.91,
    c: 25934.12,
    v: 741.3807400000001,
  },
  {
    t: 1693209600,
    o: 25934.13,
    h: 25987.19,
    l: 25922.27,
    c: 25969.72,
    v: 892.4358699999999,
  },
  {
    t: 1693213200,
    o: 25969.71,
    h: 25970.89,
    l: 25864.5,
    c: 25931.54,
    v: 1250.2310000000002,
  },
  {
    t: 1693216800,
    o: 25931.55,
    h: 25968.69,
    l: 25912.75,
    c: 25953.45,
    v: 434.79643,
  },
  {
    t: 1693220400,
    o: 25953.45,
    h: 26004,
    l: 25945.84,
    c: 26000.99,
    v: 498.19777999999997,
  },
  {
    t: 1693224000,
    o: 26000.99,
    h: 26253.99,
    l: 25991.44,
    c: 26136.55,
    v: 3335.71372,
  },
  {
    t: 1693227600,
    o: 26136.54,
    h: 26163.62,
    l: 26090,
    c: 26101.31,
    v: 1021.2272800000001,
  },
  {
    t: 1693231200,
    o: 26101.3,
    h: 26159.04,
    l: 26080.6,
    c: 26139.61,
    v: 785.24864,
  },
  {
    t: 1693234800,
    o: 26139.62,
    h: 26190,
    l: 26044.11,
    c: 26104.17,
    v: 1144.84005,
  },
  {
    t: 1693238400,
    o: 26104.16,
    h: 26160,
    l: 26095.88,
    c: 26146.92,
    v: 691.13291,
  },
  {
    t: 1693242000,
    o: 26146.91,
    h: 26191.79,
    l: 26135.75,
    c: 26141.79,
    v: 708.8377200000001,
  },
  {
    t: 1693245600,
    o: 26141.79,
    h: 26148.91,
    l: 26100,
    c: 26148.91,
    v: 557.95259,
  },
  {
    t: 1693249200,
    o: 26148.91,
    h: 26155,
    l: 25918,
    c: 25996.26,
    v: 1719.05245,
  },
  {
    t: 1693252800,
    o: 25996.27,
    h: 26023.74,
    l: 25972.95,
    c: 25993.03,
    v: 486.81590000000006,
  },
  {
    t: 1693256400,
    o: 25993.04,
    h: 26010.68,
    l: 25946.18,
    c: 25982.67,
    v: 561.9352700000001,
  },
  {
    t: 1693260000,
    o: 25982.68,
    h: 26067.53,
    l: 25981.73,
    c: 26061.41,
    v: 604.48365,
  },
  {
    t: 1693263600,
    o: 26061.41,
    h: 26178.83,
    l: 26048.77,
    c: 26120,
    v: 925.1375300000001,
  },
  {
    t: 1693267200,
    o: 26120,
    h: 26165.99,
    l: 26084.07,
    c: 26140.44,
    v: 617.80751,
  },
  {
    t: 1693270800,
    o: 26140.44,
    h: 26206.24,
    l: 26093.46,
    c: 26106.34,
    v: 1108.0434599999999,
  },
  {
    t: 1693274400,
    o: 26106.34,
    h: 26117.57,
    l: 26080.19,
    c: 26104.69,
    v: 517.15535,
  },
  {
    t: 1693278000,
    o: 26104.69,
    h: 26105.9,
    l: 26066.36,
    c: 26083.19,
    v: 397.27485,
  },
  {
    t: 1693281600,
    o: 26083.18,
    h: 26091.36,
    l: 26051,
    c: 26057.87,
    v: 496.74667999999997,
  },
  {
    t: 1693285200,
    o: 26057.86,
    h: 26095.08,
    l: 26032.07,
    c: 26065.15,
    v: 2202.25561,
  },
  {
    t: 1693288800,
    o: 26065.15,
    h: 26065.16,
    l: 26005,
    c: 26008.66,
    v: 663.09474,
  },
  {
    t: 1693292400,
    o: 26008.67,
    h: 26049.23,
    l: 25990.58,
    c: 26004.2,
    v: 788.89577,
  },
  {
    t: 1693296000,
    o: 26004.2,
    h: 26015.72,
    l: 25950,
    c: 25956.43,
    v: 1308.19881,
  },
  {
    t: 1693299600,
    o: 25956.44,
    h: 25992.23,
    l: 25950.74,
    c: 25992.23,
    v: 730.00833,
  },
  {
    t: 1693303200,
    o: 25992.23,
    h: 25992.23,
    l: 25922,
    c: 25981.27,
    v: 925.5882200000001,
  },
  {
    t: 1693306800,
    o: 25981.27,
    h: 26046,
    l: 25961.97,
    c: 25975.55,
    v: 894.47402,
  },
  {
    t: 1693310400,
    o: 25975.54,
    h: 26044.51,
    l: 25964.79,
    c: 26044.51,
    v: 572.2758200000001,
  },
  {
    t: 1693314000,
    o: 26044.5,
    h: 26103.43,
    l: 26006,
    c: 26052,
    v: 920.12168,
  },
  {
    t: 1693317600,
    o: 26052,
    h: 27692,
    l: 26052,
    c: 27492.03,
    v: 23508.553509999998,
  },
  {
    t: 1693321200,
    o: 27492.03,
    h: 27550,
    l: 27265.24,
    c: 27423.44,
    v: 8125.1353899999995,
  },
  {
    t: 1693324800,
    o: 27423.44,
    h: 28142.85,
    l: 27350.01,
    c: 27999.99,
    v: 12941.325139999999,
  },
  {
    t: 1693328400,
    o: 27999.99,
    h: 28097.91,
    l: 27793.33,
    c: 27892.07,
    v: 5403.896360000001,
  },
  {
    t: 1693332000,
    o: 27892.08,
    h: 28020.73,
    l: 27835.84,
    c: 27971.79,
    v: 2640.80836,
  },
  {
    t: 1693335600,
    o: 27971.78,
    h: 27995,
    l: 27823.91,
    c: 27831.33,
    v: 2122.35963,
  },
  {
    t: 1693339200,
    o: 27831.33,
    h: 27883.61,
    l: 27561.24,
    c: 27569.28,
    v: 2779.70746,
  },
  {
    t: 1693342800,
    o: 27569.28,
    h: 27733.32,
    l: 27564.39,
    c: 27658.99,
    v: 1538.245,
  },
  {
    t: 1693346400,
    o: 27658.98,
    h: 27693.98,
    l: 27532.79,
    c: 27638.28,
    v: 1297.33249,
  },
  {
    t: 1693350000,
    o: 27638.29,
    h: 27779.12,
    l: 27567.7,
    c: 27716.34,
    v: 1752.6906900000001,
  },
  {
    t: 1693353600,
    o: 27716.34,
    h: 27768.57,
    l: 27622.24,
    c: 27622.24,
    v: 1726.34734,
  },
  {
    t: 1693357200,
    o: 27622.24,
    h: 27643.03,
    l: 27455.91,
    c: 27552.62,
    v: 1975.16695,
  },
  {
    t: 1693360800,
    o: 27552.63,
    h: 27562.07,
    l: 27349.39,
    c: 27400.56,
    v: 2212.69703,
  },
  {
    t: 1693364400,
    o: 27400.55,
    h: 27426.35,
    l: 27280.99,
    c: 27391.13,
    v: 1733.27718,
  },
  {
    t: 1693368000,
    o: 27391.14,
    h: 27446.8,
    l: 27391.13,
    c: 27443,
    v: 741.83502,
  },
  {
    t: 1693371600,
    o: 27443,
    h: 27476.03,
    l: 27435.64,
    c: 27469.04,
    v: 623.8542,
  },
  {
    t: 1693375200,
    o: 27469.05,
    h: 27469.05,
    l: 27371.94,
    c: 27382.21,
    v: 875.8126,
  },
  {
    t: 1693378800,
    o: 27382.21,
    h: 27464.8,
    l: 27382.21,
    c: 27436.25,
    v: 957.43753,
  },
  {
    t: 1693382400,
    o: 27436.25,
    h: 27499,
    l: 27406.65,
    c: 27470.98,
    v: 1179.68546,
  },
  {
    t: 1693386000,
    o: 27470.97,
    h: 27471.92,
    l: 27310.05,
    c: 27426.8,
    v: 1477.1533599999998,
  },
  {
    t: 1693389600,
    o: 27426.79,
    h: 27448.86,
    l: 27400,
    c: 27435.44,
    v: 568.79669,
  },
  {
    t: 1693393200,
    o: 27435.44,
    h: 27435.45,
    l: 27331.45,
    c: 27338.51,
    v: 1066.6878900000002,
  },
  {
    t: 1693396800,
    o: 27338.52,
    h: 27425,
    l: 27292.24,
    c: 27353.64,
    v: 1666.51156,
  },
  {
    t: 1693400400,
    o: 27353.64,
    h: 27464.45,
    l: 27145.23,
    c: 27376.07,
    v: 3215.90683,
  },
  {
    t: 1693404000,
    o: 27376.07,
    h: 27424.4,
    l: 27017.24,
    c: 27110.01,
    v: 4624.91525,
  },
  {
    t: 1693407600,
    o: 27110,
    h: 27293.9,
    l: 27036.66,
    c: 27201.63,
    v: 2931.78962,
  },
  {
    t: 1693411200,
    o: 27201.63,
    h: 27210.84,
    l: 27067.9,
    c: 27155.37,
    v: 1682.43167,
  },
  {
    t: 1693414800,
    o: 27155.37,
    h: 27249.99,
    l: 27117,
    c: 27167.98,
    v: 1266.21889,
  },
  {
    t: 1693418400,
    o: 27167.97,
    h: 27286.88,
    l: 27100,
    c: 27279.98,
    v: 1146.53159,
  },
  {
    t: 1693422000,
    o: 27279.98,
    h: 27286.88,
    l: 27178.26,
    c: 27178.28,
    v: 948.19683,
  },
  {
    t: 1693425600,
    o: 27178.27,
    h: 27291.34,
    l: 27178.27,
    c: 27250.15,
    v: 798.05652,
  },
  {
    t: 1693429200,
    o: 27250.15,
    h: 27337.78,
    l: 27250.15,
    c: 27259.69,
    v: 748.69344,
  },
  {
    t: 1693432800,
    o: 27259.69,
    h: 27326.87,
    l: 27241,
    c: 27241,
    v: 785.4259599999999,
  },
  {
    t: 1693436400,
    o: 27241,
    h: 27319.88,
    l: 27228,
    c: 27299.99,
    v: 494.82907,
  },
  {
    t: 1693440000,
    o: 27299.99,
    h: 27303.36,
    l: 27198.7,
    c: 27222.13,
    v: 524.79039,
  },
  {
    t: 1693443600,
    o: 27222.13,
    h: 27240.69,
    l: 27179.12,
    c: 27196.48,
    v: 629.65419,
  },
  {
    t: 1693447200,
    o: 27196.48,
    h: 27233.75,
    l: 27186.3,
    c: 27190.01,
    v: 556.81561,
  },
  {
    t: 1693450800,
    o: 27190.01,
    h: 27217.28,
    l: 27178.88,
    c: 27217.27,
    v: 448.14294,
  },
  {
    t: 1693454400,
    o: 27217.28,
    h: 27285.68,
    l: 27215,
    c: 27252.3,
    v: 513.52534,
  },
  {
    t: 1693458000,
    o: 27252.3,
    h: 27274.31,
    l: 27226.38,
    c: 27251.29,
    v: 463.60802,
  },
  {
    t: 1693461600,
    o: 27251.29,
    h: 27290.95,
    l: 27233.28,
    c: 27253.2,
    v: 505.16607,
  },
  {
    t: 1693465200,
    o: 27253.2,
    h: 27294.97,
    l: 27232.88,
    c: 27261.03,
    v: 723.86238,
  },
  {
    t: 1693468800,
    o: 27261.03,
    h: 27273.97,
    l: 27222.01,
    c: 27235.08,
    v: 746.40612,
  },
  {
    t: 1693472400,
    o: 27235.08,
    h: 27235.09,
    l: 27184.99,
    c: 27198.27,
    v: 1062.78551,
  },
  {
    t: 1693476000,
    o: 27198.26,
    h: 27226.18,
    l: 27191.48,
    c: 27201.83,
    v: 527.69401,
  },
  {
    t: 1693479600,
    o: 27201.83,
    h: 27587.51,
    l: 27201.17,
    c: 27303.96,
    v: 3750.77348,
  },
  {
    t: 1693483200,
    o: 27303.95,
    h: 27315.47,
    l: 27063.08,
    c: 27192.62,
    v: 2860.0215900000003,
  },
  {
    t: 1693486800,
    o: 27192.62,
    h: 27205.32,
    l: 27073.14,
    c: 27149.99,
    v: 2265.8806299999997,
  },
  {
    t: 1693490400,
    o: 27149.99,
    h: 27192.78,
    l: 27027,
    c: 27175.66,
    v: 2072.55887,
  },
  {
    t: 1693494000,
    o: 27175.67,
    h: 27179.74,
    l: 26803.73,
    c: 26922.01,
    v: 4168.47827,
  },
  {
    t: 1693497600,
    o: 26922.01,
    h: 26924.87,
    l: 26218.65,
    c: 26234.23,
    v: 9337.81648,
  },
  {
    t: 1693501200,
    o: 26234.22,
    h: 26400.43,
    l: 26133.94,
    c: 26329.07,
    v: 4324.91773,
  },
  {
    t: 1693504800,
    o: 26329.08,
    h: 26387.32,
    l: 26189.77,
    c: 26292.23,
    v: 1827.4644500000002,
  },
  {
    t: 1693508400,
    o: 26292.24,
    h: 26319.4,
    l: 25928,
    c: 26162.79,
    v: 4521.91272,
  },
  {
    t: 1693512000,
    o: 26162.79,
    h: 26194.86,
    l: 25959.72,
    c: 26022.61,
    v: 2429.7760599999997,
  },
  {
    t: 1693515600,
    o: 26022.61,
    h: 26037.97,
    l: 25655.01,
    c: 26024.57,
    v: 4573.05529,
  },
  {
    t: 1693519200,
    o: 26024.57,
    h: 26072.73,
    l: 25976.15,
    c: 26053.96,
    v: 1140.40181,
  },
  {
    t: 1693522800,
    o: 26053.95,
    h: 26057.99,
    l: 25912.01,
    c: 25940.78,
    v: 1057.29605,
  },
  {
    t: 1693526400,
    o: 25940.77,
    h: 26070,
    l: 25931.11,
    c: 26051.97,
    v: 1040.94084,
  },
  {
    t: 1693530000,
    o: 26051.97,
    h: 26057.9,
    l: 25974.13,
    c: 25985.72,
    v: 1107.76693,
  },
  {
    t: 1693533600,
    o: 25985.71,
    h: 26100,
    l: 25985.71,
    c: 26058.62,
    v: 965.9866999999999,
  },
  {
    t: 1693537200,
    o: 26058.62,
    h: 26094.9,
    l: 26043.63,
    c: 26081.01,
    v: 538.8385800000001,
  },
  {
    t: 1693540800,
    o: 26081,
    h: 26095,
    l: 26039,
    c: 26044.46,
    v: 577.4744000000001,
  },
  {
    t: 1693544400,
    o: 26044.46,
    h: 26057.54,
    l: 26025.4,
    c: 26027.27,
    v: 557.51426,
  },
  {
    t: 1693548000,
    o: 26027.26,
    h: 26042.9,
    l: 25978,
    c: 25997,
    v: 838.31043,
  },
  {
    t: 1693551600,
    o: 25996.99,
    h: 26015,
    l: 25979.35,
    c: 25990.92,
    v: 866.1265599999999,
  },
  {
    t: 1693555200,
    o: 25990.92,
    h: 26014.45,
    l: 25957.37,
    c: 25980.46,
    v: 622.48417,
  },
  {
    t: 1693558800,
    o: 25980.46,
    h: 26049.83,
    l: 25975.24,
    c: 26033.97,
    v: 790.47731,
  },
  {
    t: 1693562400,
    o: 26033.98,
    h: 26047.39,
    l: 25987,
    c: 25999.31,
    v: 711.09676,
  },
  {
    t: 1693566000,
    o: 25999.32,
    h: 26072.34,
    l: 25981.79,
    c: 26047.49,
    v: 1210.1124800000002,
  },
  {
    t: 1693569600,
    o: 26047.5,
    h: 26156,
    l: 25990.61,
    c: 26063.27,
    v: 2914.54752,
  },
  {
    t: 1693573200,
    o: 26063.27,
    h: 26064.66,
    l: 25874.05,
    c: 26000,
    v: 2140.06414,
  },
  {
    t: 1693576800,
    o: 26000.01,
    h: 26000.01,
    l: 25691.6,
    c: 25717,
    v: 3234.3527,
  },
  {
    t: 1693580400,
    o: 25717.01,
    h: 25935.7,
    l: 25566.53,
    c: 25811,
    v: 4100.63064,
  },
  {
    t: 1693584000,
    o: 25811,
    h: 25964.17,
    l: 25755,
    c: 25789.78,
    v: 2787.27058,
  },
  {
    t: 1693587600,
    o: 25789.77,
    h: 25800.15,
    l: 25333.75,
    c: 25478.86,
    v: 6773.85576,
  },
  {
    t: 1693591200,
    o: 25478.85,
    h: 25834.86,
    l: 25455.41,
    c: 25654.23,
    v: 3160.8702399999997,
  },
  {
    t: 1693594800,
    o: 25654.22,
    h: 25679.88,
    l: 25581.24,
    c: 25645.66,
    v: 1221.52144,
  },
  {
    t: 1693598400,
    o: 25645.65,
    h: 25937.42,
    l: 25565.55,
    c: 25776.62,
    v: 2396.5177400000002,
  },
  {
    t: 1693602000,
    o: 25776.62,
    h: 25862.77,
    l: 25734.92,
    c: 25804.49,
    v: 1085.28174,
  },
];

export default chartCandles;
