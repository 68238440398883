import chartCandles from "./data";

export default function exchangeDatafeed(
  timeFrames,
  subscribeHandler,
  unsubscribeHandler,
  getMarksHandler = () => {},
) {
  return {
    onReady: (callback) => {
      const configurationData = {
        supported_resolutions: timeFrames,
        supports_marks: true,
        exchanges: [
          {
            value: "Bitycle",
            name: "Bitycle",
            desc: "Bitycle",
          },
        ],
        symbols_types: [
          {
            name: "crypto",
            value: "crypto",
          },
        ],
      };
      setTimeout(() => callback(configurationData));
    },
    searchSymbols: async (
      userInput,
      exchange,
      symbolType,
      onResultReadyCallback,
    ) => {
      onResultReadyCallback([]);
    },
    resolveSymbol: async (
      symbolName,
      onSymbolResolvedCallback,
      onResolveErrorCallback,
    ) => {
      const market = symbolName.replace("/", "");
      const symbolInfo = {
        ticker: market,
        name: market,
        session: "24x7",
        timezone: "UTC",
        exchange: "Bitycle",
        description: market,
        minmov: 1,
        pricescale: 1000,
        has_intraday: true,
        has_no_volume: false,
        has_weekly_and_monthly: true,
        supported_resolutions: timeFrames,
        volume_precision: 2,
        data_status: "streaming",
      };

      onSymbolResolvedCallback(symbolInfo);
    },

    getBars: async (
      symbolInfo,
      resolution,
      periodParams,
      onHistoryCallback,
      onErrorCallback,
    ) => {
      let countBack = periodParams.countBack;
      let bars = [];

      chartCandles.forEach((bar) => {
        bars.push({
          time: bar.t * 1000,
          low: bar.l,
          high: bar.h,
          open: bar.o,
          close: bar.c,
          volume: bar.v,
          market: symbolInfo.ticker,
        });
      });
      onHistoryCallback(
        bars.sort((a, b) => a.time - b.time),
        {
          noData: bars.length === 0,
        },
      );
    },
    getMarks: (symbolInfo, from, to, onDataCallback, resolution) => {
      getMarksHandler(onDataCallback);
    },
    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
    ) => {
      subscribeHandler(onRealtimeCallback, symbolInfo, resolution);
    },
    unsubscribeBars: (subscriberUID) => {
      unsubscribeHandler(subscriberUID);
    },
  };
}
