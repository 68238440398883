function Card(props) {
  return (
    <div className="card">
      <br />

      <div style={{ alignItems: "center", justifyContent: "center" , display:"flex"}}>
        {props.children}
      </div>
      <div className="text-center">
        <h3 className="card-title">{props.title}</h3>
      </div>
      <div className="p-3">
        <p className="card-text">{props.text}</p>
      </div>
    </div>
  );
}
export default Card;
